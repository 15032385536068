import { SegmentCampaign } from '../../service/segment-campaign';
import { head, isNil } from 'lodash';
import {
  CampaignCPCIncentive,
  CampaignSegment
} from '../../../../domainTypes/campaigns';
import { default as React } from 'react';
import { useSegmentTotals } from './Segment/useSegmentQuery';
import { useMappedLoadingValue } from '../../../../services/db';
import { Alert, Skeleton } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '../../../../components/Number';

const InnerUplift = ({
  segment,
  cpc,
  pageConstraints
}: {
  segment: CampaignSegment;
  cpc: CampaignCPCIncentive;
  pageConstraints: SegmentCampaign['pageConstraints'];
}) => {
  const [currentEpc, loading] = useMappedLoadingValue(
    useSegmentTotals(segment, pageConstraints),
    (response) => response?.totals.analytics?.epc_net?.curr
  );
  if (loading) return <Skeleton />;
  if (!currentEpc) return null;
  const allowedClickCount = cpc.limit / cpc.amount;
  const prevEarningsPotential = currentEpc * allowedClickCount;
  const uplift = cpc.limit - prevEarningsPotential;
  const ratio = Math.round((cpc.amount / currentEpc) * 100) / 100;
  return (
    <Alert>
      <Typography variant="body2">
        This looks like a <strong>{ratio}x EPC</strong> and an estimated{' '}
        <strong>net lift of {formatCurrency(uplift, 'USD', true)}</strong> based
        on your selected products.
      </Typography>
    </Alert>
  );
};

export const Uplift = ({
  segments,
  incentives,
  pageConstraints
}: Pick<SegmentCampaign, 'segments' | 'incentives' | 'pageConstraints'>) => {
  const segment = head(segments);
  if (isNil(segment)) return null;
  const cpc = incentives.find((i) => i.type === 'cpc') as CampaignCPCIncentive;
  if (isNil(cpc) || cpc.amount === 0 || cpc.limit === 0) return null;
  return (
    <InnerUplift
      segment={segment}
      cpc={cpc}
      pageConstraints={pageConstraints}
    />
  );
};
