import { isEmpty } from 'lodash';
import React from 'react';
import { Clock } from 'react-feather';
import { Helmet } from 'react-helmet';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { COLORS } from '../../../../domainTypes/colors';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainerVertical } from '../../../../layout/Flex';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import {
  SegmentCampaignList,
  SegmentCampaignListHeader
} from '../../components/SegmentCampaignList';
import { useSegmentCampaigns } from '../../service';
import { groupCampaignsByStatus } from '../../service/segment-campaign';

const statuses = [
  'running',
  'scheduled',
  'approved',
  'proposed',
  'draft'
] as const;

const Body = () => {
  const [data, loading] = useMappedLoadingValue(
    useSegmentCampaigns(),
    (campaigns) => {
      const campaignsByStatus = groupCampaignsByStatus(campaigns);
      return statuses
        .map((status) => ({
          status,
          campaigns: campaignsByStatus[status] || []
        }))
        .filter((d) => !isEmpty(d.campaigns));
    }
  );

  return (
    <div
      className={css((t) => ({
        marginTop: t.spacing(2),
        padding: t.spacing(2)
      }))}
    >
      {loading || !data ? (
        <Centered>
          <Loader size={32} />
        </Centered>
      ) : isEmpty(data) ? (
        <Centered>
          <EmptySearchState
            icon={Clock}
            title={"You don't have any active campaigns."}
            message={'Create a campaign using Create button.'}
            color={COLORS.blue.blue5}
            bgColor={COLORS.blue.blue1}
          />
        </Centered>
      ) : (
        <FlexContainerVertical spacing={3}>
          {data.map(({ status, campaigns }) => {
            return (
              <React.Fragment key={status}>
                <SegmentCampaignListHeader status={status} />
                <SegmentCampaignList data={campaigns} />
              </React.Fragment>
            );
          })}
        </FlexContainerVertical>
      )}
    </div>
  );
};

export const SegmentCampaignActive: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  return (
    <>
      <Helmet>
        <title>Campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canView ? <Body /> : <NoPermissions />}
      </CampaignsPageBody>
    </>
  );
};
