import React, { useState } from 'react';
import { AnalyticsResponseRowWithComparison } from '../../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../../domainTypes/emptyConstants';
import { useMappedLoadingValue } from '../../../../../services/db';
import { useProductCatalogMatchQuery } from '../../../../ProductCatalog/service';
import {
  SegmentQueryParts,
  useSegmentQueryBase
} from '../Segment/useSegmentQuery';
import { ButtonBase, InputLabel, Typography } from '@material-ui/core';
import { compact, isNil, sortBy, uniq } from 'lodash';
import { SectionHeading } from '../../../../../components/DrawerHeader';
import { CampaignSegment } from '../../../../../domainTypes/campaigns';
import { Stack } from '../../manual-campaign-form/form-components';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { FlexContainer } from '../../../../../layout/Flex';
import { X } from 'react-feather';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

const MyAutocomplete: React.FC<{
  value: string;
  onChange: (value: string) => void;
  options: string[];
  filterOptions: (options: string[], state: { inputValue: string }) => string[];
}> = ({ value, onChange, options, filterOptions }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = React.useState('');

  return (
    <Autocomplete
      value={value}
      onChange={(ev: any, nextValue: string | null) => {
        if (isNil(nextValue)) {
          setOpen(false);
          setInputValue('');
          return;
        }
        onChange(nextValue);
        setOpen(false);
        setInputValue(inputValue);
      }}
      filterOptions={filterOptions}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus
      options={options}
      disableCloseOnSelect
      style={{ width: '100%' }}
      onInputChange={(ev, v) => setInputValue(v)}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
};

const PageUrlAutocomplete = ({
  value,
  onChange,
  options
}: {
  value: string[];
  onChange: (value: string[]) => void;
  options: string[];
}) => {
  return (
    <>
      <MyAutocomplete
        value={''}
        onChange={(v) => onChange(uniq(compact([...value, v])))}
        options={options}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            (option) => option.includes(inputValue) && !value.includes(option)
          )
        }
      />
      <FlexContainer spacing={1} direction="column" alignItems="flex-start">
        {value.map((url) => (
          <FlexContainer key={url} spacing={0.5}>
            <Typography
              variant="body2"
              style={{ textDecorationLine: 'underline' }}
            >
              {url}
            </Typography>
            <ButtonBase
              onClick={() => onChange(value.filter((v) => v !== url))}
            >
              <X size={16} />
            </ButtonBase>
          </FlexContainer>
        ))}
      </FlexContainer>
    </>
  );
};

const PageConstraintsForm = ({
  value,
  onChange,
  options
}: {
  value: SegmentCampaign['pageConstraints'];
  onChange: (value: SegmentCampaign['pageConstraints']) => void;
  options: string[];
}) => {
  return (
    <Stack>
      <SectionHeading>PAGE CONSTRAINTS</SectionHeading>
      <InputLabel>Include pages</InputLabel>
      <Typography variant="body2">
        Only include links in this campaign, which are features on the listed
        pages.
      </Typography>
      <PageUrlAutocomplete
        value={value.include}
        onChange={(nextInclude) => onChange({ ...value, include: nextInclude })}
        options={options}
      />
      <div />
      <InputLabel>Exclude pages</InputLabel>
      <Typography variant="body2">
        Links on listed pages will be excluded from this campaign.
      </Typography>
      <PageUrlAutocomplete
        value={value.exclude}
        onChange={(nextExclude) => onChange({ ...value, exclude: nextExclude })}
        options={options}
      />
    </Stack>
  );
};

const QUERY_PARTS: SegmentQueryParts = { totalsSelect: ['agg_uniq_page_url'] };

const Inner = ({
  value,
  onChange,
  segment
}: {
  value: SegmentCampaign['pageConstraints'];
  onChange: (value: SegmentCampaign['pageConstraints']) => void;
  segment: CampaignSegment;
}) => {
  const query = useSegmentQueryBase(segment, QUERY_PARTS);
  const [options] = useMappedLoadingValue(
    useProductCatalogMatchQuery(query),
    (r) =>
      sortBy(
        (r.totals.analytics as AnalyticsResponseRowWithComparison['data'])
          .agg_uniq_page_url?.curr || EMPTY_ARR,
        (x: string) => x
      )
  );
  // Two branches on purpose, so that the form is forced to re-render when the data lands
  if (options) {
    return (
      <PageConstraintsForm
        value={value}
        onChange={onChange}
        options={options}
      />
    );
  }
  return (
    <PageConstraintsForm
      value={value}
      onChange={onChange}
      options={EMPTY_ARR}
    />
  );
};

export const CampaignPageConstraintsPicker = ({
  value,
  onChange,
  segments
}: {
  value: SegmentCampaign['pageConstraints'];
  onChange: (value: SegmentCampaign['pageConstraints']) => void;
  segments: CampaignSegment[];
}) => {
  const segment = segments[0];
  if (!segment) {
    return (
      <PageConstraintsForm
        value={value}
        onChange={onChange}
        options={EMPTY_ARR}
      />
    );
  }
  return <Inner value={value} onChange={onChange} segment={segment} />;
};
