import { compact, partition, sumBy } from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsSelectField,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import {
  CAMPAIGN_ANALYTICS_DEFAULT_LAST_X_DAYS,
  CampaignSegment
} from '../../../../../domainTypes/campaigns';
import {
  ProductCatalogAvailability,
  ProductCatalogField,
  ProductCatalogFilter,
  ProductCatalogMatchQuery,
  ProductCatalogMatchQueryResponse
} from '../../../../../domainTypes/productCatalog';
import { toTimeRanges } from '../../../../../hooks/timeframe';
import { useCurrentUser } from '../../../../../services/currentUser';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { productCatalogMatchQuery } from '../../../../ProductCatalog/service';
import { usePromise } from '../../../../../hooks/usePromise';
import { pub_queryAnalyticsV2 } from '../../../../../services/analyticsV2/query';
import { LoadingValueExtended } from '../../../../../services/db';
import { useFeatureEnabled } from '../../../../../services/features';
import { EMPTY_OBJ } from '../../../../../domainTypes/emptyConstants';

export interface SegmentQueryParts {
  orderBy?: ProductCatalogMatchQuery['orderBy'];
  paginate?: ProductCatalogMatchQuery['paginate'];
  sourceSelect?: ProductCatalogField[];
  targetSelect?: ProductCatalogField[];
  sourceAnalyticsSelect?: AnalyticsSelectField[];
  analyticsFilters?: AnalyticsFilter[];
  totalsSelect?: AnalyticsSelectField[];
}

const DEFAULTS_TOTALS_SELECT: AnalyticsSelectField[] = [
  'count_uniq_page_url',
  'count_uniq_link_id',
  'agg_uniq_link_id',
  'agg_uniq_pk',
  'count_uniq_p_catalog_uid',
  'count_uniq_l_advertiser_id',
  'c',
  'gmv_sum_net',
  'commission_sum_net',
  'epc_net'
];

export type SegmentTotalsResponse = LoadingValueExtended<
  ProductCatalogMatchQueryResponse & { estimated?: boolean }
>;

export const useRangeForCampaignQueries = () => {
  const { tz } = useCurrentUser();
  const { range } = useMemo(
    () =>
      toTimeRanges(
        {
          range: {
            kind: 'period',
            value: {
              type: 'last',
              duration: `P${CAMPAIGN_ANALYTICS_DEFAULT_LAST_X_DAYS}D`
            }
          },
          comparison: { kind: 'disabled' }
        },
        tz
      ),
    [tz]
  );
  return range;
};

export const useSegmentQueryBase = (
  segment: Pick<CampaignSegment, 'filters' | 'maId' | 'exclusions'>,
  {
    orderBy,
    paginate,
    sourceSelect,
    sourceAnalyticsSelect,
    targetSelect,
    totalsSelect,
    analyticsFilters
  }: SegmentQueryParts = {}
) => {
  const { space } = useCurrentUser();
  const range = useRangeForCampaignQueries();

  return useMemo<ProductCatalogMatchQuery>(() => {
    const allFilters: ProductCatalogFilter[] = compact([
      ...segment.filters,
      {
        field: 'uid',
        condition: 'not in',
        values: segment.exclusions ?? []
      }
    ]);

    return {
      spaceId: space.id,
      maId: segment.maId,
      source: {
        select: sourceSelect,
        filters: allFilters,
        analytics: sourceAnalyticsSelect
          ? {
              range,
              select: sourceAnalyticsSelect,
              filters: analyticsFilters
            }
          : undefined
      },
      target: {
        select: targetSelect,
        filters: [
          {
            field: 'availability',
            condition: 'in',
            values: [ProductCatalogAvailability.in_stock]
          }
        ]
      },
      totals: {
        analytics: {
          range,
          select: totalsSelect ?? DEFAULTS_TOTALS_SELECT,
          filters: analyticsFilters
        }
      },
      orderBy,
      paginate
    };
  }, [
    analyticsFilters,
    orderBy,
    paginate,
    range,
    segment.exclusions,
    segment.filters,
    segment.maId,
    sourceAnalyticsSelect,
    sourceSelect,
    space.id,
    targetSelect,
    totalsSelect
  ]);
};

export const fetchAmazonTotals = async (
  spaceId: string,
  linkIds: string[],
  range: ISOTimeRange
) => {
  const amazonQuery: AnalyticsQuery = {
    range,
    select: ['epc_net', 'gmv_sum_net', 'c'],
    filters: [
      {
        field: 'pk',
        condition: 'in',
        values: ['amazon']
      }
    ]
  };

  const linksQuery: AnalyticsQuery = {
    range,
    select: ['c', 'commission_sum_net'],
    groupBy: ['pk'],
    filters: [
      {
        field: 'link_id',
        condition: 'in',
        values: linkIds
      }
    ]
  };

  const [amazonStats, linkStatsResponse] = await Promise.all([
    pub_queryAnalyticsV2(spaceId, amazonQuery),
    pub_queryAnalyticsV2(spaceId, linksQuery)
  ]);

  const amazonClicks = amazonStats.rows[0].data.c?.curr ?? 0;
  const amazonEpc = amazonStats.rows[0].data.epc_net?.curr ?? 0;
  const amazonGmv = amazonStats.rows[0].data.gmv_sum_net?.curr ?? 0;
  const amazonGmvPerClick = amazonClicks === 0 ? 0 : amazonGmv / amazonClicks;

  const [amazonLinks, otherLinks] = partition(
    linkStatsResponse.rows,
    (row) => row.group.pk === 'amazon'
  );

  const otherEarnings = sumBy(
    otherLinks,
    (row) => row.data.commission_sum_net?.curr ?? 0
  );
  const amazonEarnings = sumBy(
    amazonLinks,
    (row) => (row.data.c?.curr ?? 0) * amazonEpc
  );

  const otherGmv = sumBy(otherLinks, (row) => row.data.gmv_sum_net?.curr ?? 0);
  const amazonLinksGmv = sumBy(
    amazonLinks,
    (row) => (row.data.c?.curr ?? 0) * amazonGmvPerClick
  );

  const allClicks = sumBy(
    linkStatsResponse.rows,
    (row) => row.data.c?.curr ?? 0
  );
  const allEarnings = otherEarnings + amazonEarnings;
  const allGmv = otherGmv + amazonLinksGmv;
  const epc = allEarnings / allClicks;

  return {
    epc,
    gmv: allGmv,
    earnings: allEarnings
  };
};

const useSegmentTotalsInner = (
  segment: Pick<CampaignSegment, 'filters' | 'maId' | 'exclusions'>,
  pageConstraints: SegmentCampaign['pageConstraints'],
  queryParts: SegmentQueryParts
) => {
  const { space } = useCurrentUser();
  const range = useRangeForCampaignQueries();
  const skipAmazonStuff = useFeatureEnabled('AMAZON_SUBTAGS');
  const analyticsFilters = useMemo<AnalyticsFilter[]>(() => {
    return compact([
      pageConstraints.include.length && {
        field: 'page_url',
        condition: 'in',
        values: pageConstraints.include
      },
      pageConstraints.exclude.length && {
        field: 'page_url',
        condition: 'not in',
        values: pageConstraints.exclude
      }
    ]);
  }, [pageConstraints.exclude, pageConstraints.include]);
  const parts = useMemo(
    () => ({
      ...queryParts,
      analyticsFilters: analyticsFilters,
      totalsSelect: queryParts.totalsSelect ?? DEFAULTS_TOTALS_SELECT
    }),
    [analyticsFilters, queryParts]
  );
  const query = useSegmentQueryBase(segment, parts);
  return usePromise(async () => {
    const productCatalogMatches = await productCatalogMatchQuery(query);
    const hasAmazon = productCatalogMatches.totals.analytics?.agg_uniq_pk?.curr?.includes(
      'amazon'
    );
    if (skipAmazonStuff || !hasAmazon) {
      return productCatalogMatches;
    }

    const linkIds =
      productCatalogMatches.totals.analytics?.agg_uniq_link_id?.curr ?? [];

    const { gmv, earnings, epc } = await fetchAmazonTotals(
      space.id,
      linkIds,
      range
    );

    return {
      estimated: true,
      ...productCatalogMatches,
      totals: {
        ...productCatalogMatches.totals,
        analytics: {
          ...productCatalogMatches.totals.analytics,
          epc_net: {
            curr: epc
          },
          commission_sum_net: {
            curr: earnings
          },
          gmv_sum_net: {
            curr: gmv
          }
        }
      }
    };
  }, [space.id, query, range, skipAmazonStuff]);
};

export const useSegmentTotals = (
  segment: Pick<CampaignSegment, 'filters' | 'maId' | 'exclusions'>,
  pageConstraints: SegmentCampaign['pageConstraints']
) => {
  return useSegmentTotalsInner(segment, pageConstraints, EMPTY_OBJ);
};

export const useSegmentTotalsWithTopProducts = (
  segment: Pick<CampaignSegment, 'filters' | 'maId' | 'exclusions'>,
  pageConstraints: SegmentCampaign['pageConstraints']
) => {
  const queryParts = useMemo<SegmentQueryParts>(
    () => ({
      orderBy: {
        field: 'source.analytics.c',
        direction: 'DESC'
      },
      paginate: {
        limit: 20,
        page: 1
      },
      sourceSelect: ['uid', 'title', 'link', 'image_link', 'brand', 'price'],
      sourceAnalyticsSelect: ['c'],
      targetSelect: ['availability']
    }),
    []
  );
  return useSegmentTotalsInner(segment, pageConstraints, queryParts);
};
