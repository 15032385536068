// TODO: push to domain types
import { callFirebaseFunction } from '../../../services/firebaseFunctions';

export interface RerouteData {
  source_image_link: string;
  source_link: string;
  source_title: string;
  source_retailer: string;
  target_image_link: string;
  target_link: string;
  target_title: string;
  target_retailer: string;
}

export const getCampaignRerouteData = async (
  spaceId: string,
  campaignId: string
) => {
  return await callFirebaseFunction<RerouteData[]>(
    'campaigns-pub_getSegmentCampaignRerouteItems',
    {
      spaceId,
      campaignId
    }
  );
};