import { Skeleton } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { ItemSorter, RowsRenderer } from '../../../../components/GroupableList';
import { ProfileLabel } from '../../../../components/ProfileLabel';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import { Truncated } from '../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../components/WithHoverIndicator';
import { SegmentCampaignStatus } from '../../../../domainTypes/campaigns';
import { Doc } from '../../../../domainTypes/document';
import { useRoutes } from '../../../../routes';
import { getCampaignLogEntry } from '../../../../services/campaigns';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useMarketplaceAdvertiserProfilesPublic } from '../../../../services/marketplaceAdvertisers';
import { toMoment } from '../../../../services/time';
import { FirstLogTimestamp } from '../../service/log';
import {
  CampaignStatusIconWithLabel,
  SegmentCampaign
} from '../../service/segment-campaign';
import { Managers } from '../manual-campaigns-list/Managers';
import { TeamWithColorLazy } from '../teams/TeamWithColor';

type Column = 'name' | 'partner' | 'manager' | 'team' | 'createdAt';

const SORTER: ItemSorter<Doc<SegmentCampaign>> = {
  key: 'createdAt',
  items: {
    sort: (c) => {
      const entry = getCampaignLogEntry(c.data, 'save-draft');
      if (!entry) return 0;
      return toMoment(entry.timestamp).valueOf();
    },
    dir: 'desc'
  }
};

const COLUMNS: Array<IColumn<Doc<SegmentCampaign>, Column>> = [
  {
    key: 'name',
    head: () => 'Campaign name',
    align: 'left',
    cell: (c) => (
      <WithHoverIndicator>
        <Truncated title={c.data.name} />
      </WithHoverIndicator>
    ),
    width: 200,
    flexGrow: 1
  },
  {
    key: 'partner',
    head: () => 'Partner',
    align: 'left',
    cell: function Partner(c) {
      const { space } = useCurrentUser();
      const [
        partner,
        loading
      ] = useMappedLoadingValue(
        useMarketplaceAdvertiserProfilesPublic(space.id),
        (partners) => partners.find((p) => p.id === c.data.partner?.id)
      );
      if (loading) {
        return <Skeleton />;
      }
      if (!partner) {
        return <Dash />;
      }
      return <ProfileLabel profile={partner} />;
    },
    width: 200,
    flexGrow: 1
  },
  {
    key: 'team',
    head: () => 'Team',
    align: 'left',
    cell: (c) => <TeamWithColorLazy teamId={c.data.team} />,
    width: 200,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Created at',
    align: 'left',
    cell: (c) => <FirstLogTimestamp campaign={c.data} operation="save-draft" />,
    width: 200,
    flexGrow: 1
  },
  {
    key: 'manager',
    head: () => 'Managers',
    align: 'left',
    cell: (c) => <Managers userIds={c.data.managers} />,
    width: 100,
    flexGrow: 1
  }
];

export const SegmentCampaignListHeader = ({
  status
}: {
  status: SegmentCampaignStatus;
}) => {
  return (
    <CampaignStatusIconWithLabel
      status={status}
      labelProps={{ variant: 'body1' }}
    />
  );
};

export const SegmentCampaignList = ({
  data
}: {
  data: Array<Doc<SegmentCampaign>>;
  linkToDetails?: boolean;
}) => {
  const { ROUTES } = useRoutes();

  const rowToHref = useCallback(
    (c: Doc<SegmentCampaign>) => {
      switch (c.data.status) {
        case 'running':
        case 'completed':
          return ROUTES.campaigns.segment.report.url(c.data.id);
        default:
          return ROUTES.campaigns.segment.details.url(c.data.id, 'overview');
      }
    },
    [ROUTES.campaigns.segment.details, ROUTES.campaigns.segment.report]
  );
  return (
    <RowsRenderer
      rows={data}
      columns={COLUMNS}
      renderHead
      sorter={SORTER}
      otherProps={undefined}
      rowToKey={(c) => c.id}
      rowToHref={rowToHref}
    />
  );
};
