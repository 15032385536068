import React from 'react';
import {
  UserAvatarGroupByIds,
  UserAvatarWithNameNextToIt
} from '../../../../components/UserAvatars';
import { useCurrentUser } from '../../../../services/currentUser';
import { useUserInSpaceById } from '../../../../services/user';

export const Managers = ({
  userIds,
  size
}: {
  userIds: string[];
  size?: number;
}) => {
  const { space } = useCurrentUser();
  return (
    <UserAvatarGroupByIds
      spaceId={space.id}
      userIds={userIds}
      max={3}
      size={size}
    />
  );
};

export const Manager = ({
  userId,
  size
}: {
  userId: string;
  size?: number;
}) => {
  const { space } = useCurrentUser();
  const [user] = useUserInSpaceById(space.id, userId);
  return (
    <UserAvatarWithNameNextToIt profile={user ? user.data : null} size={size} />
  );
};
