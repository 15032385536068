import React, { useMemo } from 'react';
import { ISOTimeRange } from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import { useSpaceCurrency } from '../../../../../services/useSpaceCurrency';
import { useColumnsQueryParam } from '../../../../../components/analytics_v2/Table/hooks';
import { FlexContainer } from '../../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { Paper } from '@material-ui/core';
import { RowsRenderer } from '../../../../../components/GroupableList';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { Badge } from '../../../../../components/Badge';
import { COLORS } from '../../../../../domainTypes/colors';
import { CountCell } from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import { Truncated } from '../../../../../components/Truncated';
import { TeamWithColor } from '../TeamWithColor';
import { sumBy, uniq } from 'lodash';
import { Dash } from '../../../../../components/Table/CountCell';
import { Managers } from '../../manual-campaigns-list/Managers';
import {
  teamCampaignPerformance,
  TeamWithCampaigns
} from '../../../service/teams-report';
import moment from 'moment-timezone';

type TeamReportColumn =
  | 'team'
  | 'managers'
  | 'campaigns'
  | 'advertisers'
  | 'rateIncreases'
  | 'flatSpend'
  | 'forecast'
  | 'obtained'
  | 'status';

export interface CampaignsTeamsTableContext {
  currency: CurrencyCode;
  timeframe: ISOTimeRange;
}

type TeamReportColumnDefinition = IColumn<
  TeamWithCampaigns,
  TeamReportColumn,
  CampaignsTeamsTableContext
>;

const numberCell = (value: number | undefined, dashWhenAllZero = false) => (
  <CountCell
    before={0}
    after={value ?? 0}
    compare={false}
    dashWhenAllZero={dashWhenAllZero}
  />
);

const currencyCell = (value: number | undefined, currency: CurrencyCode) => (
  <CountCell
    before={0}
    after={value ?? 0}
    compare={false}
    currency={currency}
    dashWhenAllZero
  />
);

const ALL_TEAM_REPORT_COLUMNS: TeamReportColumnDefinition[] = [
  {
    key: 'team',
    head: () => 'Team',
    align: 'left',
    cell: (r) => (
      <WithHoverIndicator>
        <Truncated title={r.team.name}>
          <TeamWithColor id={r.team.teamId} name={r.team.name} />
        </Truncated>
      </WithHoverIndicator>
    ),
    width: 200
  },
  {
    key: 'managers',
    head: () => 'Managers',
    align: 'left',
    cell: (r) => {
      const managers = uniq(r.campaigns.flatMap((c) => c.managers));
      if (managers.length === 0) return <Dash size={12} />;
      return <Managers userIds={managers} size={24} />;
    },
    width: 80
  },
  {
    key: 'campaigns',
    head: () => 'Campaigns',
    align: 'right',
    cell: (r) => numberCell(r.campaigns.length),
    width: 100
  },
  {
    key: 'advertisers',
    head: () => 'Advertisers',
    align: 'right',
    cell: (r) => {
      const advertisersCount = sumBy(r.campaigns, (c) => c.advertisers.length);
      return numberCell(advertisersCount, true);
    },
    width: 100
  },
  {
    key: 'rateIncreases',
    head: () => 'Rate Increases',
    align: 'right',
    cell: (r) => {
      const rateIncreases = sumBy(
        r.campaigns,
        (c) => c.incentives.filter((i) => i.type === 'rateIncrease').length
      );
      return numberCell(rateIncreases, true);
    },
    width: 100
  },
  {
    key: 'flatSpend',
    head: () => 'Flat Spend',
    align: 'right',
    cell: (r, o) => {
      const { flatSpend } = teamCampaignPerformance(r, o);
      return currencyCell(flatSpend, o.currency);
    },
    width: 200
  },
  {
    key: 'forecast',
    head: () => 'Forecast',
    align: 'right',
    cell: (r, o) => {
      const { forecast } = teamCampaignPerformance(r, o);
      return currencyCell(forecast, o.currency);
    },
    width: 100
  },
  {
    key: 'obtained',
    head: () => '% Obtained',
    align: 'right',
    cell: (r, o) => {
      const { flatSpend, forecast } = teamCampaignPerformance(r, o);
      if (!forecast) return <Dash size={12} />;
      return (
        <CountCell
          format="percent"
          digits={2}
          before={0}
          after={flatSpend / forecast}
          compare={false}
          dashWhenAllZero={true}
        />
      );
    },
    width: 100
  },
  {
    key: 'status',
    head: () => 'Status',
    align: 'center',
    cell: (r, o) => {
      const notInTimeframe =
        moment().isAfter(o.timeframe.end) ||
        moment().isBefore(o.timeframe.start);
      if (notInTimeframe) return <Dash size={12} />;
      const { flatSpend, forecast } = teamCampaignPerformance(r, o);
      if (!forecast) return <Dash size={12} />;
      const timeRatio =
        moment().diff(o.timeframe.start, 'days') /
        moment(o.timeframe.end).diff(o.timeframe.start, 'days');
      const goalRatio = flatSpend / forecast;
      return goalRatio >= timeRatio ? <OnTrack /> : <Behind />;
    },
    width: 150
  }
];

const Behind = () => (
  <Badge color={COLORS.gold.gold8} bgColor={COLORS.gold.gold2}>
    Behind
  </Badge>
);

const OnTrack = () => (
  <Badge color={COLORS.blue.blue6} bgColor={COLORS.blue.blue2}>
    On Track
  </Badge>
);

const defaultTeamReportColumns: TeamReportColumn[] = [
  'team',
  'campaigns',
  'managers',
  'advertisers',
  'flatSpend',
  'forecast',
  'obtained',
  'status'
];

export const CampaignsTeamsTable: React.FC<{
  data: Array<TeamWithCampaigns>;
  timeframe: ISOTimeRange;
}> = ({ data, timeframe }) => {
  const { ROUTES } = useRoutes();
  const currency = useSpaceCurrency();
  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    defaultTeamReportColumns
  );
  const columnDefinitions = useMemo(
    () => ALL_TEAM_REPORT_COLUMNS.filter((c) => columns.has(c.key)),
    [columns]
  );

  const otherProps = useMemo<CampaignsTeamsTableContext>(
    () => ({ currency, timeframe }),
    [currency, timeframe]
  );

  return (
    <FlexContainer direction="column" spacing={1} alignItems="stretch">
      <FlexContainer justifyContent="space-between">
        <Typography component="h1" variant="h6">
          Campaigns by team
        </Typography>

        <ColumnSelector
          value={columns}
          onChange={setColumns}
          columns={ALL_TEAM_REPORT_COLUMNS}
        />
      </FlexContainer>
      <Paper>
        <RowsRenderer
          renderHead
          rows={data}
          columns={columnDefinitions}
          sorter={{
            key: 'team',
            items: {
              sort: (a) => a.team.name,
              dir: 'asc'
            }
          }}
          otherProps={otherProps}
          rowToKey={(r) => r.team.teamId}
          rowToHref={(d) =>
            ROUTES.campaigns.manual.active.url({
              filters: [
                {
                  k: 'campaign_team',
                  v: [d.team.teamId]
                }
              ]
            })
          }
        />
      </Paper>
    </FlexContainer>
  );
};
