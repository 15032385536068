import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Pause, Play } from 'react-feather';
import { AlertBox } from '../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { DialogTitleGray } from '../../../../../components/DialogTitleGray';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { FlexContainer } from '../../../../../layout/Flex';
import { ReadySegmentCampaign } from '../../../service/segment-campaign';
import { pauseSegmentCampaign } from '../../../service/segment-campaign-form';

export const PauseButton = ({
  campaign
}: {
  campaign: ReadySegmentCampaign;
}) => {
  const dialog = useDialogState();
  if (campaign.status !== 'running') {
    return null;
  }
  if (campaign.paused) {
    return (
      <ButtonWithPromise
        variant="text"
        pending={<Typography variant="body1">Resuming campaign...</Typography>}
        onClick={async () => {
          await pauseSegmentCampaign(campaign.spaceId, campaign.id, false);
        }}
      >
        <Typography color="textSecondary">
          <FlexContainer alignItems="center" spacing={0.5}>
            <Play size={18} />
            <Typography variant="body1" color="textSecondary">
              Resume campaign
            </Typography>
          </FlexContainer>
        </Typography>
      </ButtonWithPromise>
    );
  }
  return (
    <>
      <Button variant="text" onClick={dialog.openDialog}>
        <Typography color="textSecondary">
          <FlexContainer alignItems="center" spacing={0.5}>
            <Pause size={18} />
            <Typography variant="body1" color="textSecondary">
              Pause campaign
            </Typography>
          </FlexContainer>
        </Typography>
      </Button>
      <Dialog open={dialog.dialogOpen} onClose={dialog.closeDialog}>
        <DialogTitleGray>Pause campaign?</DialogTitleGray>
        <DialogContent>
          <Typography variant="body1">
            This campaign is is currently running. You can pause the campaign to
            check, change, or modify the campaign scope. While a campaign is
            paused, clicks will not be counted towards the goal, links won't be
            re-routed, and you won't earn any additional money. You'll be able
            to preview and resume the campaign afterwards.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialog.closeDialog}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending={'Pausing...'}
            onClick={async () => {
              await pauseSegmentCampaign(campaign.spaceId, campaign.id, true);
              dialog.closeDialog();
            }}
          >
            Pause
          </ButtonWithPromise>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PauseBanner = ({
  campaign
}: {
  campaign: ReadySegmentCampaign;
}) => {
  if (campaign.status !== 'running' || !campaign.paused) {
    return null;
  }
  return (
    <AlertBox variant="pending">
      <Typography variant="body1">
        This campaign is currently paused. Your links will not be re-routed.
      </Typography>
    </AlertBox>
  );
};
