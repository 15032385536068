import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { AlertTriangle, Edit, PlusCircle, XCircle } from 'react-feather';
import shortid from 'shortid';
import { InlineFiltersDrawer } from '../../../../../components/analytics_v2/Filters/Drawer/FiltersDrawer';
import { productFiltersList } from '../../../../../components/analytics_v2/Filters/Drawer/filtersTree';
import { brandFilterUI } from '../../../../../components/analytics_v2/Filters/FilterUI/product_catalog/BrandFilterUI';
import { manufacturerFilterUI } from '../../../../../components/analytics_v2/Filters/FilterUI/product_catalog/ManufacturerFilterUI';
import { retailerFilterUI } from '../../../../../components/analytics_v2/Filters/FilterUI/product_catalog/RetailerFilterUI';
import { useProductCatalogFiltersInner } from '../../../../../components/analytics_v2/Filters/useProductCatalogFilters';
import { EmptySearchState } from '../../../../../components/EmptySearchState';
import { Loader } from '../../../../../components/Loader';
import {
  CampaignPartner,
  CampaignSegment
} from '../../../../../domainTypes/campaigns';
import { COLORS } from '../../../../../domainTypes/colors';
import { EMPTY_OBJ } from '../../../../../domainTypes/emptyConstants';
import { FiltersDefinition } from '../../../../../domainTypes/filters';
import { ProductCatalogFilter } from '../../../../../domainTypes/productCatalog';
import { css } from '../../../../../emotion';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import { useCurrentUser } from '../../../../../services/currentUser';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { Header, Stack } from '../../manual-campaign-form/form-components';
import { SegmentTotals } from './SegmentTotals';
import { SegmentTopProducts } from './SegmentTopProducts';
import { SegmentNameField } from './SegmentNameField';
import { SegmentDialogEmptyState } from './SegmentDialogEmptyState';
import {
  useSegmentTotals,
  useSegmentTotalsWithTopProducts
} from './useSegmentQuery';
import { isNil } from 'lodash';

export const SegmentSummaryInner = ({
  segment,
  pageConstraints
}: {
  segment: CampaignSegment;
  pageConstraints: SegmentCampaign['pageConstraints'];
}) => {
  const totals = useSegmentTotals(segment, pageConstraints);
  return <SegmentTotals totalsResponse={totals} />;
};

const SegmentStats: React.FC<{
  maId: string;
  filters: ProductCatalogFilter[];
  exclusions?: string[];
  pageConstraints: SegmentCampaign['pageConstraints'];
}> = ({ pageConstraints, ...segment }) => {
  const totals = useSegmentTotalsWithTopProducts(segment, pageConstraints);
  const [data, loading] = totals;

  return loading || !data ? (
    <Centered height={550}>
      <Loader size={32} />
    </Centered>
  ) : data.totals.matchCount === 0 ? (
    <Centered height={550}>
      <EmptySearchState
        icon={AlertTriangle}
        title="No matching products available"
        message="Change the filters to find products that match your existing inventory to products from the partner's catalog."
        color={COLORS.gold.gold6}
        bgColor={COLORS.gold.gold2}
      />
    </Centered>
  ) : (
    <>
      <SegmentTotals totalsResponse={totals} />
      <SegmentTopProducts rows={data.rows} />
    </>
  );
};

const UIS = [retailerFilterUI, manufacturerFilterUI, brandFilterUI];

const toFilterDefinitions = (
  productFilters: ProductCatalogFilter[]
): FiltersDefinition =>
  productFilters.flatMap(
    (f): FiltersDefinition => {
      switch (f.field) {
        case 'retailer': {
          if (f.condition !== 'in') return [];
          return [
            {
              k: 'product_catalog_retailer',
              v: f.values
            }
          ];
        }
        case 'manufacturer': {
          if (f.condition !== 'in') return [];
          return [
            {
              k: 'product_catalog_manufacturer',
              v: f.values
            }
          ];
        }
        case 'brand': {
          if (f.condition !== 'in') return [];
          return [
            {
              k: 'product_catalog_brand',
              v: f.values
            }
          ];
        }
        default:
          return [];
      }
    }
  );

const fromFilterDefinitions = (
  definitions: FiltersDefinition
): ProductCatalogFilter[] =>
  definitions.flatMap((d) => {
    switch (d.k) {
      case 'product_catalog_retailer':
        return retailerFilterUI.toClauses(d);
      case 'product_catalog_manufacturer':
        return manufacturerFilterUI.toClauses(d);
      case 'product_catalog_brand':
        return brandFilterUI.toClauses(d);
      default:
        return [];
    }
  });

const SegmentDialogBody: React.FC<{
  onCancel: () => void;
  initialSegment: CampaignSegment | null;
  onSave: (segment: CampaignSegment) => void;
  maId: string;
  pageConstraints: SegmentCampaign['pageConstraints'];
}> = ({ onCancel, onSave, maId, initialSegment, pageConstraints }) => {
  const { space } = useCurrentUser();
  const [segment, setSegment] = useState(
    () => initialSegment ?? emptySegment(space.id, maId)
  );
  const setName = useCallback(
    (name: string) => setSegment({ ...segment, name }),
    [segment]
  );
  const filterDefinitions = useMemo<FiltersDefinition>(
    () => toFilterDefinitions(segment.filters),
    [segment.filters]
  );
  const setFilterDefinitions = useCallback(
    (def: FiltersDefinition) => {
      setSegment({
        ...segment,
        filters: fromFilterDefinitions(def)
      });
    },
    [segment]
  );
  const { filters, drawerProps } = useProductCatalogFiltersInner(
    filterDefinitions,
    setFilterDefinitions,
    UIS,
    EMPTY_OBJ,
    {
      initialState: true
    }
  );

  const handleSave = useCallback(() => {
    onSave({
      ...segment,
      filters
    });
    onCancel();
  }, [onSave, segment, filters, onCancel]);

  return (
    <>
      <DialogTitle
        className={css((t) => ({
          backgroundColor: t.palette.background.default
        }))}
      >
        <FlexContainer justifyContent="space-between">
          <div>
            <SegmentNameField value={segment.name} onChange={setName} />
            <Typography variant="body2" color="textSecondary">
              A segment is a bundle of links you want to offer as part of a
              campaign.
            </Typography>
          </div>
          <div>
            <Button color="default" variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleSave}>
              Save segment
            </Button>
          </div>
        </FlexContainer>
      </DialogTitle>
      <DialogContent
        className={css(() => ({ minWidth: 800, marginTop: '-24px' }))}
      >
        <div style={{ marginLeft: `-18px` }}>
          <InlineFiltersDrawer
            {...drawerProps}
            tree={productFiltersList}
            title="Filter products"
          />
        </div>
        {filters.length === 0 ? (
          <SegmentDialogEmptyState
            maId={maId}
            addFilter={drawerProps.addFilter}
          />
        ) : (
          <SegmentStats
            filters={filters}
            maId={maId}
            exclusions={segment.exclusions}
            pageConstraints={pageConstraints}
          />
        )}
      </DialogContent>
    </>
  );
};

const SegmentDialog: React.FC<{
  open: boolean;
  onCancel: () => void;
  segment: CampaignSegment | null;
  onSave: (segment: CampaignSegment) => void;
  maId: string;
  pageConstraints: SegmentCampaign['pageConstraints'];
}> = ({ open, onCancel, maId, onSave, segment, pageConstraints }) => (
  <Dialog open={open} onClose={onCancel} scroll="body" maxWidth="xl">
    <SegmentDialogBody
      onCancel={onCancel}
      maId={maId}
      pageConstraints={pageConstraints}
      onSave={onSave}
      initialSegment={segment}
    />
  </Dialog>
);

interface CampaignSegmentsPickerProps {
  value: SegmentCampaign['segments'];
  onChange: (value: SegmentCampaign['segments']) => void;
  partner: CampaignPartner | null;
  pageConstraints: SegmentCampaign['pageConstraints'];
}

const emptySegment = (spaceId: string, maId: string): CampaignSegment => {
  return {
    id: shortid(),
    type: 'filterBased',
    maId,
    spaceId,
    name: 'New links segment',
    filters: []
  };
};

const SegmentSummary: React.FC<{
  pageConstraints: SegmentCampaign['pageConstraints'];
  segment: CampaignSegment;
  onEdit: () => void;
  onDelete: () => void;
}> = ({ segment, onDelete, onEdit, pageConstraints }) => {
  return (
    <div>
      <FlexContainer alignItems="center" spacing={0}>
        <ButtonBase onClick={onEdit} style={{ color: COLORS.blue.blue6 }}>
          <Typography variant="body1">
            <strong>{segment.name}</strong>
          </Typography>{' '}
          &nbsp;
          <Edit size={16} />
        </ButtonBase>
        <IconButton onClick={onDelete}>
          <XCircle size={16} />
        </IconButton>
      </FlexContainer>
      {segment.filters.length > 0 ? (
        <SegmentSummaryInner
          segment={segment}
          pageConstraints={pageConstraints}
        />
      ) : null}
    </div>
  );
};

const SegmentField: React.FC<{
  value: CampaignSegment;
  onChange: (s: CampaignSegment) => void;
  onDelete: () => void;
  maId: string;
  pageConstraints: SegmentCampaign['pageConstraints'];
}> = ({ value, maId, onChange, onDelete, pageConstraints }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  return (
    <>
      <SegmentDialog
        open={dialogOpen}
        onCancel={closeDialog}
        segment={value}
        onSave={onChange}
        maId={maId}
        pageConstraints={pageConstraints}
      />
      <SegmentSummary
        segment={value}
        onEdit={openDialog}
        onDelete={onDelete}
        pageConstraints={pageConstraints}
      />
    </>
  );
};

export const CampaignSegmentsPicker: React.FC<CampaignSegmentsPickerProps> = ({
  partner,
  pageConstraints,
  value,
  onChange
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  if (isNil(partner) || partner.type !== 'partner') {
    return (
      <Stack>
        <Header>
          LINK SEGMENTS
          <Tooltip title="Select your campaign partner first" placement="top">
            <Button
              color="default"
              style={{ marginLeft: 12, opacity: 0.5 }}
              startIcon={<PlusCircle size={16} />}
            >
              Add links
            </Button>
          </Tooltip>
          <Typography variant="body2" color="textPrimary">
            First, select a partner to work with before deciding which links to
            offer.
          </Typography>
        </Header>
      </Stack>
    );
  }
  const maId = partner.id;
  const handleCreateSegment = (segment: CampaignSegment) => {
    onChange([...value, segment]);
  };

  return (
    <>
      <SegmentDialog
        open={dialogOpen}
        onCancel={closeDialog}
        segment={null}
        onSave={handleCreateSegment}
        maId={maId}
        pageConstraints={pageConstraints}
      />
      <Stack>
        <Header>
          LINK SEGMENTS
          <Button
            color="primary"
            style={{ marginLeft: 12 }}
            onClick={openDialog}
            startIcon={<PlusCircle size={16} />}
            disabled={value.length > 0}
          >
            Add links
          </Button>
          <Typography variant="body2" color="textPrimary">
            Bundle up the product links you want to offer based on overlap with
            your partner's catalog.
          </Typography>
          {value.map((segment) => {
            const handleSegmentChange = (nextSegment: CampaignSegment) =>
              onChange(
                value.map((s) => (s.id === nextSegment.id ? nextSegment : s))
              );
            const handleSegmentRemoval = () =>
              onChange(value.filter((s) => s.id !== segment.id));
            return (
              <SegmentField
                key={segment.id}
                value={segment}
                onChange={handleSegmentChange}
                onDelete={handleSegmentRemoval}
                maId={maId}
                pageConstraints={pageConstraints}
              />
            );
          })}
        </Header>
      </Stack>
    </>
  );
};
