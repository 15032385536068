import { useCurrentUserScopes } from '../../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { CampaignsPageBody } from '../../components/CampaignsPageBody';
import { NoPermissions } from '../../../../components/NoPermissions';
import React from 'react';
import { useSegmentCampaigns } from '../../service';
import { useMappedLoadingValue } from '../../../../services/db';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { Loader } from '../../../../components/Loader';
import { FlexContainerVertical } from '../../../../layout/Flex';
import {
  SegmentCampaignList,
  SegmentCampaignListHeader
} from '../../components/SegmentCampaignList';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import { Clock } from 'react-feather';
import { COLORS } from '../../../../domainTypes/colors';

const Body = () => {
  const [data, loading] = useMappedLoadingValue(
    useSegmentCampaigns(),
    (campaigns) => campaigns.filter((c) => c.data.status === 'completed')
  );
  return (
    <div
      className={css((t) => ({
        marginTop: t.spacing(2),
        padding: t.spacing(2)
      }))}
    >
      {loading || !data ? (
        <Centered>
          <Loader size={32} />
        </Centered>
      ) : isEmpty(data) ? (
        <Centered height={350}>
          <EmptySearchState
            icon={Clock}
            title={"You don't have any completed campaigns."}
            message={'Campaigns will appear here when they are completed.'}
            color={COLORS.blue.blue5}
            bgColor={COLORS.blue.blue1}
          />
        </Centered>
      ) : (
        <FlexContainerVertical spacing={3}>
          <SegmentCampaignListHeader status="completed" />
          <SegmentCampaignList data={data} />
        </FlexContainerVertical>
      )}
    </div>
  );
};

export const SegmentCampaignComplete = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  return (
    <>
      <Helmet>
        <title>Campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canView ? <Body /> : <NoPermissions />}
      </CampaignsPageBody>
    </>
  );
};
