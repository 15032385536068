import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { isNil, sortBy, truncate } from 'lodash';
import { Moment } from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronsRight, ExternalLink, Image } from 'react-feather';
import { Field, Form } from 'react-final-form';
import { getCampaignPages } from '../../../../../admin/features/Campaings/service';
import { CustomPagination } from '../../../../../components/CustomPagination';
import { DateRangePicker } from '../../../../../components/DateRangePicker';
import { FieldContainer } from '../../../../../components/FieldContainer';
import { required } from '../../../../../components/Form/validators';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { Loader } from '../../../../../components/Loader';
import { Message } from '../../../../../components/Message';
import { RetailerName } from '../../../../../components/ProductCatalogRetailer';
import { SearchInput } from '../../../../../components/SearchInput';
import { Dash } from '../../../../../components/Table/CountCell';
import {
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../../../domainTypes/analytics_v2';
import { COLORS } from '../../../../../domainTypes/colors';
import { EMPTY_OBJ } from '../../../../../domainTypes/emptyConstants';
import { css } from '../../../../../emotion';
import { usePromise } from '../../../../../hooks/usePromise';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Centered } from '../../../../../layout/Centered';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { LimitedWidth } from '../../../../../layout/PageBody';
import { useCurrentUser } from '../../../../../services/currentUser';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../services/db';
import { useFeatureEnabled } from '../../../../../services/features';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { momentRangeToIsoRange } from '../../../../../services/time';
import { ProductImageContainer } from '../../../../Links/pages/Overview/components/ProductLinkCell';
import {
  getCampaignRerouteData,
  RerouteData
} from '../../../service/reroute-data';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { scheduleSegmentCampaign } from '../../../service/segment-campaign-form';
import { CampaignMetricTotals } from '../CampaignMetricTotals';
import {
  fetchAmazonTotals,
  useRangeForCampaignQueries
} from '../Segment/useSegmentQuery';

interface ScheduleFormFields {
  timeframe: {
    start: Moment;
    end: Moment;
  } | null;
}

const PAGE_URLS_PAGE_SIZE = 10;
const PreviewCampaignPagesList: React.FC<{ pageUrls: string[] }> = ({
  pageUrls
}) => {
  const [search, _setSearch] = useState('');
  const [page, setPage] = useState(1);
  const setSearch = useCallback((str) => {
    setPage(1);
    _setSearch(str);
  }, []);
  const filteredUrls = useMemo(() => {
    return sortBy(pageUrls).filter((page) => page.includes(search));
  }, [pageUrls, search]);
  const urls = useMemo(() => {
    return filteredUrls.slice(
      (page - 1) * PAGE_URLS_PAGE_SIZE,
      page * PAGE_URLS_PAGE_SIZE
    );
  }, [filteredUrls, page]);

  return (
    <FlexContainerVertical spacing={2}>
      <FlexContainer justifyContent="space-between" fullWidth>
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="Search by URL"
          width={300}
        />
        <CustomPagination
          onChange={(_e, nextPage) => setPage(nextPage)}
          page={page}
          count={Math.ceil(filteredUrls.length / PAGE_URLS_PAGE_SIZE)}
          siblingCount={0}
        />
      </FlexContainer>
      <div>
        {urls.map((url) => (
          <FlexContainer key={url} wrap="wrap">
            <Typography variant="body1">{url}</Typography>
            <a href={`${url}?am-preview=true`} target="_blank" rel="noreferrer">
              <Typography variant="body1" color="primary">
                <FlexContainer alignItems="center" spacing={0.5}>
                  Preview <ExternalLink size={18} />
                </FlexContainer>
              </Typography>
            </a>
          </FlexContainer>
        ))}
      </div>
    </FlexContainerVertical>
  );
};

const PreviewCampaignPages: React.FC<{ campaign: SegmentCampaign }> = ({
  campaign
}) => {
  const { space } = useCurrentUser();
  const [data, loading, error] = usePromise(
    () => getCampaignPages(space.id, campaign.id),
    [space.id, campaign.id]
  );
  if (loading || !data) {
    return (
      <Centered height={60}>
        <Loader size={24} />
      </Centered>
    );
  }
  if (error) {
    return <Message message="Failed to load pages" />;
  }
  return <PreviewCampaignPagesList pageUrls={data} />;
};

const PRODUCTS_PAGE_SIZE = 10;

const Product = ({
  imageLink,
  link,
  retailer,
  title
}: {
  imageLink: string;
  retailer: string;
  link: string;
  title: string;
}) => (
  <FlexContainer spacing={2} alignItems="flex-start">
    <ProductImageContainer>
      {imageLink ? (
        <img src={imageLink} alt={title || ''} />
      ) : (
        <Image size={24} />
      )}
    </ProductImageContainer>
    <FlexContainerVertical fullWidth spacing={0.5}>
      <LinkExternal href={link}>
        <strong>{truncate(title || '', { length: 110 })}</strong>
      </LinkExternal>
      {retailer ? <RetailerName retailer={retailer} /> : <Dash />}
    </FlexContainerVertical>
  </FlexContainer>
);

const PreviewCampaignProductsList: React.FC<{ products: RerouteData[] }> = ({
  products
}) => {
  const [search, _setSearch] = useState('');
  const [page, setPage] = useState(1);
  const setSearch = useCallback((str) => {
    setPage(1);
    _setSearch(str);
  }, []);
  const filteredProducts = useMemo(() => {
    return sortBy(products, (p) => p.source_title).filter(
      (p) => p.source_title.includes(search) || p.target_title.includes(search)
    );
  }, [products, search]);

  const productsOnPage = useMemo(() => {
    return filteredProducts.slice(
      (page - 1) * PRODUCTS_PAGE_SIZE,
      page * PRODUCTS_PAGE_SIZE
    );
  }, [filteredProducts, page]);

  return (
    <FlexContainerVertical spacing={2}>
      <FlexContainer justifyContent="space-between" fullWidth>
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="Search by product name"
          width={300}
        />
        <CustomPagination
          onChange={(_e, nextPage) => setPage(nextPage)}
          page={page}
          count={Math.ceil(filteredProducts.length / PRODUCTS_PAGE_SIZE)}
          siblingCount={0}
        />
      </FlexContainer>
      <div
        className={css((t) => ({
          display: 'grid',
          gridTemplateColumns: '6fr 1fr 6fr',
          columnGap: t.spacing(2),
          rowGap: t.spacing(2),
          width: '100%',
          alignItems: 'center'
        }))}
      >
        {productsOnPage.map((product) => (
          <React.Fragment key={product.source_link}>
            <Product
              imageLink={product.source_image_link}
              title={product.source_title}
              retailer={product.source_retailer}
              link={product.source_link}
            />
            <ChevronsRight size={18} color={COLORS.blue.blue5} />
            <Product
              imageLink={product.target_image_link}
              title={product.target_title}
              retailer={product.target_retailer}
              link={product.target_link}
            />
          </React.Fragment>
        ))}
      </div>
    </FlexContainerVertical>
  );
};

const PreviewCampaignProducts: React.FC<{
  campaign: SegmentCampaign;
}> = ({ campaign }) => {
  const { space } = useCurrentUser();
  const [data, loading, error] = usePromise(
    () => getCampaignRerouteData(space.id, campaign.id),
    [space.id, campaign.id]
  );

  if (loading || !data) {
    return (
      <Centered height={60}>
        <Loader size={24} />
      </Centered>
    );
  }
  if (error) {
    return <Message message="Failed to load products." />;
  }
  return <PreviewCampaignProductsList products={data} />;
};

const PreviewMetrics: React.FC<{ campaign: SegmentCampaign }> = ({
  campaign
}) => {
  const skipAmazonStuff = useFeatureEnabled('AMAZON_SUBTAGS');
  const range = useRangeForCampaignQueries();
  const { space } = useCurrentUser();
  const totals = usePromise<
    AnalyticsResponseRowWithComparison['data'] & { estimated?: boolean }
  >(async () => {
    const metrics = await callFirebaseFunction<AnalyticsResponse>(
      'campaigns-pub_getSegmentCampaignMetrics',
      {
        spaceId: space.id,
        campaignId: campaign.id
      }
    );

    const totals = metrics.rows[0]?.data || EMPTY_OBJ; // it's possible that there's no data whatsoever
    const hasAmazon = totals.agg_uniq_pk?.curr?.includes('amazon');
    if (skipAmazonStuff || !hasAmazon) {
      return totals;
    }

    const linkIds = totals.agg_uniq_link_id?.curr ?? [];

    const { gmv, epc, earnings } = await fetchAmazonTotals(
      space.id,
      linkIds,
      range
    );

    return {
      estimated: true,
      ...totals,
      epc_net: {
        curr: epc
      },
      commission_sum_net: {
        curr: earnings
      },
      gmv_net: {
        curr: gmv
      }
    };
  }, [space.id, campaign.id, range, skipAmazonStuff]);

  const [isEpcEstimated = false] = useMappedLoadingValue(
    totals,
    (r) => r.estimated
  );

  return (
    <CampaignMetricTotals
      totals={
        (totals as unknown) as LoadingValue<
          AnalyticsResponseRowWithComparison['data'] & { estimated?: boolean }
        >
      }
      isEpcEstimated={isEpcEstimated}
    />
  );
};

export const SegmentCampaignScheduleForm: React.FC<{
  campaign: SegmentCampaign;
}> = ({ campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const scheduleCampaign = useCallback(
    async (fields: ScheduleFormFields) => {
      if (isNil(fields.timeframe)) return;
      try {
        await scheduleSegmentCampaign(
          space.id,
          campaign.id,
          momentRangeToIsoRange(fields.timeframe)
        );
        enqueueSnackbar('Campaign scheduled', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Failed to schedule campaign', { variant: 'error' });
      }
    },
    [campaign.id, enqueueSnackbar, space.id]
  );
  return (
    <LimitedWidth width={1000}>
      <Card>
        <Form
          onSubmit={scheduleCampaign}
          initialValues={{
            timeframe: 'timeframe' in campaign ? campaign.timeframe : null
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <div
                  className={css((t) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: t.spacing(2),
                    rowGap: t.spacing(4)
                  }))}
                >
                  <Typography variant="h6">Preview and schedule</Typography>
                  <FieldContainer>
                    <PreviewMetrics campaign={campaign} />
                  </FieldContainer>

                  <FieldContainer>
                    <Typography variant="body2" color="textSecondary">
                      The following matches have been approved by your partner.
                    </Typography>
                    <PreviewCampaignProducts campaign={campaign} />
                  </FieldContainer>

                  <FieldContainer>
                    <Typography variant="body1">
                      Use the <strong>Preview</strong> option to test the
                      deployed campaign on your site, without recording any
                      clicks or pageviews.
                    </Typography>
                    <PreviewCampaignPages campaign={campaign} />
                  </FieldContainer>

                  <FieldContainer>
                    <InputLabel>Timeframe</InputLabel>
                    <Field name="timeframe" validate={required}>
                      {({ input, meta }) => {
                        const hasError = meta.error && meta.touched;
                        const errorText = hasError
                          ? 'Please select a timeframe for your campaign'
                          : '';
                        return (
                          <FormControl
                            required
                            variant="outlined"
                            error={hasError}
                          >
                            <DateRangePicker {...input} hasError={hasError} />
                            <FormHelperText>{errorText}</FormHelperText>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </FieldContainer>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  Schedule campaign
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    </LimitedWidth>
  );
};
