import { compact } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { ISOTimeRange } from '../../domainTypes/analytics_v2';
import {
  IProductCatalogRowV1,
  ProductCatalogField,
  ProductCatalogFilter,
  ProductCatalogFindSimilarProductsReponseV1,
  ProductCatalogGetMatchingProductsResponseV1,
  ProductCatalogHistoryInterval,
  ProductCatalogHistoryResponse,
  ProductCatalogMatchQuery,
  ProductCatalogMatchQueryResponse,
  ProductCatalogQuery,
  ProductCatalogQueryResult
} from '../../domainTypes/productCatalog';
import { useDeepEqual } from '../../hooks/useDeepEqual';
import { usePromise } from '../../hooks/usePromise';
import {
  createLogFn,
  getLogModeDefault
} from '../../services/analyticsV2/logs';
import { toChecksum } from '../../services/checksum';
import { LoadingValueExtended } from '../../services/db';
import { callFirebaseFunction } from '../../services/firebaseFunctions';
import { isLocalhost } from '../../services/localhost';

export const productCatalogFindSimilarProducts = (
  urls: string[],
  opts: {
    alwaysIncludeSimilarMatches?: boolean;
  } = {}
): Promise<{
  time: number;
  data: ProductCatalogFindSimilarProductsReponseV1[];
}> => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogFindSimilarProductsReponseV1[];
  }>('productCatalog-findSimilarProducts', {
    urls,
    opts: {
      alwaysIncludeSimilarMatches: opts.alwaysIncludeSimilarMatches ?? true
    }
  });
};

export const _productCatalogGetItems = async (
  uids: string[]
): Promise<IProductCatalogRowV1[]> => {
  const res = await callFirebaseFunction<{
    items: IProductCatalogRowV1[];
  }>('productCatalog-getProductCatalogItems', {
    uids
  });
  return res.items;
};

let PRODUCT_CATALOG_ITEMS_CACHE: {
  [uid: string]: Promise<IProductCatalogRowV1 | null>;
} = {};

export type ProductCatalogGetItemsOptions = {
  noCache?: boolean;
};

export const productCatalogGetItemsCacheFlush = () =>
  (PRODUCT_CATALOG_ITEMS_CACHE = {});

export const productCatalogGetItems = async (
  uids: string[],
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1[]> => {
  const remainingItems = opts.noCache
    ? uids
    : uids.filter((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid] === undefined);
  if (remainingItems.length) {
    const newItemsPromise = _productCatalogGetItems(remainingItems);
    remainingItems.forEach((uid) => {
      PRODUCT_CATALOG_ITEMS_CACHE[uid] = newItemsPromise.then(
        (ps) => ps.find((p) => p.uid === uid) || null
      );
    });
  }
  return Promise.all(uids.map((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid])).then(
    compact
  );
};

export const productCatalogGetItem = async (
  uid: string,
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1 | null> => {
  const items = await productCatalogGetItems([uid], opts);
  return items[0] || null;
};

export const useProductCatalogItem = (uid: string) => {
  return usePromise(() => productCatalogGetItem(uid), [uid]);
};

export const useProductCatalogItems = (uids: string[]) => {
  return usePromise(() => productCatalogGetItems(uids), [uids]);
};

let PRODUCT_CATALOG_GET_HISTORY_CACHE: {
  [key: string]: Promise<ProductCatalogHistoryResponse>;
} = {};

export const productCatalogGetHistoryCacheFlush = () =>
  (PRODUCT_CATALOG_GET_HISTORY_CACHE = {});

const _productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const res = await callFirebaseFunction<ProductCatalogHistoryResponse>(
    'productCatalog-getProductCatalogHistory',
    {
      pc_uid: uid,
      range,
      interval
    }
  );
  return res;
};

export const productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const key = [
    uid,
    range.start,
    range.end,
    interval.tz,
    interval.unit,
    interval.value
  ]
    .map((x) => x.toString())
    .join('---');
  return (PRODUCT_CATALOG_GET_HISTORY_CACHE[key] =
    PRODUCT_CATALOG_GET_HISTORY_CACHE[key] ||
    _productCatalogGetHistory(uid, range, interval));
};

export const useProductCatalogHistoryDefaultRangeAndInterval = (
  tz: string
): { range: ISOTimeRange; interval: ProductCatalogHistoryInterval } => {
  return useMemo(() => {
    const end = moment().tz(tz).startOf('d').add(1, 'day');
    const start = end.clone().subtract('30', 'days');
    return {
      range: {
        start: start.toISOString(),
        end: end.toISOString()
      },
      interval: {
        unit: 'day',
        value: 1,
        tz
      }
    };
  }, [tz]);
};

export const useProductCatalogHistory = (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  return usePromise(() => productCatalogGetHistory(uid, range, interval), [
    uid,
    useDeepEqual(range),
    useDeepEqual(interval)
  ]);
};

export const useProductCatalogHistoryWithDefaults = (
  uid: string,
  tz: string
) => {
  const { range, interval } = useProductCatalogHistoryDefaultRangeAndInterval(
    tz
  );
  return usePromise(() => productCatalogGetHistory(uid, range, interval), [
    uid,
    useDeepEqual(range),
    useDeepEqual(interval)
  ]);
};

export const _productCatalogGetMatchingProductsByIds = (uids: string[]) => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogGetMatchingProductsResponseV1[];
  }>('productCatalog-getMatchingProducts', {
    uids,
    opts: {
      limit: 15
    }
  });
};

let PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE: {
  [uid: string]: ProductCatalogGetMatchingProductsResponseV1;
} = {};

export const productCatalogGetMatchingProductsCache = () =>
  (PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE = {});

export const productCatalogGetMatchingProductsById = async (uid: string) => {
  const cached = PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid];
  if (!cached) {
    const res = await _productCatalogGetMatchingProductsByIds([uid]);
    PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid] = res.data[0];
  }
  return PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid];
};

export const useProductCatalogMatchingProductsById = (uid: string) => {
  return usePromise(() => productCatalogGetMatchingProductsById(uid), [uid]);
};

type ProductCatalogQueryCache = {
  [checksum: string]: {
    expiresAt: null | number; // a millisecond value,
    promise: Promise<ProductCatalogQueryResult>;
  };
};

const isExpired = (n: number, expiresAt: number | null) =>
  expiresAt !== null && n > expiresAt;

const CACHE_BY_SPACE: { [spaceId: string]: ProductCatalogQueryCache } = {};
const getCache = (spaceId: string) =>
  (CACHE_BY_SPACE[spaceId] = CACHE_BY_SPACE[spaceId] || {});

interface ProductCatalogQueryOptions {
  noCache?: boolean;
  logMode: 'full' | 'compact' | 'off';
  logLabel: string;
}

const logQuery = createLogFn(
  'productCatalog-query',
  'Product Catalog Query',
  'PCQ'
);

const productCatalogQuery = async (
  mode: 'PA' | 'AA' | 'ADMIN',
  spaceId: string,
  query: ProductCatalogQuery,
  opts: ProductCatalogQueryOptions
) => {
  const logMode = opts.logMode || (isLocalhost() ? 'compact' : 'off');
  const n = Date.now();
  const request = () =>
    callFirebaseFunction<ProductCatalogQueryResult>(
      mode === 'PA'
        ? 'productCatalog-publisher_query'
        : mode === 'AA'
        ? 'productCatalog-advertiser_query'
        : 'productCatalog-query',
      {
        spaceId,
        query
      }
    );

  const getRequestCached = () => {
    if (opts.noCache) {
      return {
        cached: false,
        promise: (request() as unknown) as Promise<ProductCatalogQueryResult>
      };
    }
    const cache = getCache(spaceId);
    const checksum = toChecksum({ spaceId, q: query });
    let cached = false;
    if (!cache[checksum] || isExpired(n, cache[checksum].expiresAt)) {
      cache[checksum] = {
        expiresAt: null,
        promise: request()
      };
    } else {
      cached = true;
    }
    return {
      promise: (cache[checksum].promise as unknown) as Promise<
        ProductCatalogQueryResult
      >,
      cached
    };
  };
  const req = getRequestCached();
  if (logMode !== 'off') {
    req.promise.then((res) => {
      logQuery(res as any, n, req.cached, logMode, opts.logLabel);
      return res;
    });
  }
  return req.promise;
};

export const useProductCatalogQueryForAdminApp = (
  q: ProductCatalogQuery,
  opts?: ProductCatalogQueryOptions
) => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: ProductCatalogQueryOptions = useMemo(
    () => ({
      noCache,
      logMode,
      logLabel
    }),
    [logLabel, logMode, noCache]
  );
  return usePromise(
    () => productCatalogQuery('ADMIN', '', q, optsWithDefaults),
    [q, optsWithDefaults]
  );
};

export const useProductCatalogQueryForAdvertiserApp = (
  spaceId: string,
  q: ProductCatalogQuery | null,
  opts?: ProductCatalogQueryOptions
) => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: ProductCatalogQueryOptions = useMemo(
    () => ({
      noCache,
      logMode,
      logLabel
    }),
    [logLabel, logMode, noCache]
  );
  return usePromise(
    async () =>
      q ? productCatalogQuery('AA', spaceId, q, optsWithDefaults) : null,
    [q, spaceId, optsWithDefaults]
  );
};

export const useProductCatalogQueryForPublisherApp = (
  spaceId: string,
  q: ProductCatalogQuery,
  opts?: ProductCatalogQueryOptions
) => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: ProductCatalogQueryOptions = useMemo(
    () => ({
      noCache,
      logMode,
      logLabel
    }),
    [logLabel, logMode, noCache]
  );
  return usePromise(
    () => productCatalogQuery('PA', spaceId, q, optsWithDefaults),
    [q, spaceId, optsWithDefaults]
  );
};

const MATCH_QUERY_CACHE_BY_SPACE: {
  [spaceId: string]: {
    [checksum: string]: {
      expiresAt: null | number; // a millisecond value,
      promise: Promise<ProductCatalogMatchQueryResponse>;
    };
  };
} = {};
const getMatchQueryCache = (spaceId: string) =>
  (MATCH_QUERY_CACHE_BY_SPACE[spaceId] =
    MATCH_QUERY_CACHE_BY_SPACE[spaceId] || {});

export const productCatalogMatchQuery = async (
  query: ProductCatalogMatchQuery
) => {
  const n = Date.now();
  const request = () => {
    return callFirebaseFunction<ProductCatalogMatchQueryResponse>(
      'productCatalog-publisher_matchQuery',
      { query }
    );
  };
  const cache = getMatchQueryCache(query.spaceId);
  const checksum = toChecksum(query);
  if (!cache[checksum] || isExpired(n, cache[checksum].expiresAt)) {
    cache[checksum] = {
      expiresAt: null,
      promise: request()
    };
  }
  return cache[checksum].promise;
};

export function useProductCatalogMatchQuery(
  q: ProductCatalogMatchQuery
): LoadingValueExtended<ProductCatalogMatchQueryResponse>;
export function useProductCatalogMatchQuery(
  q: null
): LoadingValueExtended<null>;
export function useProductCatalogMatchQuery(
  q: ProductCatalogMatchQuery | null
): LoadingValueExtended<ProductCatalogMatchQueryResponse | null> {
  // Might be worth dealing with better equality checks here, but then again,
  // the cache will always be there for us anyway
  return usePromise(async () => {
    return q ? productCatalogMatchQuery(q) : null;
  }, [q]);
}

export const toSearchLikeFilters = (
  field: ProductCatalogField,
  search: string
): ProductCatalogFilter[] => {
  const patterns = search
    .split(' ')
    .filter(Boolean)
    .map((x) => `%${x}%`);
  return patterns.map((pattern) => ({
    field,
    condition: 'ilike',
    pattern
  }));
};
