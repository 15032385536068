import React from 'react';
import { useMappedLoadingValue } from '../../../../../services/db';
import { AnalyticsResponseRowWithComparison } from '../../../../../domainTypes/analytics_v2';
import { SegmentTotalsResponse } from './useSegmentQuery';
import { CampaignMetricTotals } from '../CampaignMetricTotals';

export const SegmentTotals: React.FC<{
  totalsResponse: SegmentTotalsResponse;
}> = ({ totalsResponse }) => {
  const totals = useMappedLoadingValue(
    totalsResponse,
    (value) =>
      value.totals.analytics as AnalyticsResponseRowWithComparison['data']
  );

  const [isEpcEstimated = false] = useMappedLoadingValue(
    totalsResponse,
    (r) => r.estimated
  );

  return (
    <CampaignMetricTotals totals={totals} isEpcEstimated={isEpcEstimated} />
  );
};
