import { DialogTitle, DialogTitleProps } from '@material-ui/core';
import React from 'react';

export const DialogTitleGray = (props: DialogTitleProps) => {
  return (
    <DialogTitle
      {...props}
      style={{ ...props.style, backgroundColor: '#EEE' }}
    />
  );
};
