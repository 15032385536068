import {
  Button,
  CardActions,
  CardContent,
  Divider,
  InputLabel,
  TextField
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { default as React } from 'react';
import { Field, Form } from 'react-final-form';
import { AutocompleteUserMultiById } from '../../../../../components/AutocompleteUserMulti';
import { SectionHeading } from '../../../../../components/DrawerHeader';
import { FieldContainer } from '../../../../../components/FieldContainer';
import { required } from '../../../../../components/Form/validators';
import {
  CampaignCPCIncentive,
  CampaignIncentive
} from '../../../../../domainTypes/campaigns';
import { css } from '../../../../../emotion';
import { SegmentCampaign } from '../../../service/segment-campaign';
import { SegmentCampaignDraft } from '../../../service/segment-campaign-form';
import { CampaignGoalsSelector } from '../../manual-campaign-form/CampaignGoalsSelector';
import { CampaignIncentivesSelector } from '../../manual-campaign-form/CampaignIncentivesSelector';
import { TeamSelector } from '../../teams/TeamSelector';
import { CampaignPartnerSelector } from '../CampaignPartnerSelector';
import { CampaignSegmentsPicker } from '../Segment/CampaignSegmentsPicker';
import { Uplift } from '../Uplift';
import { CampaignPageConstraintsPicker } from './CampaignPageConstraintsPicker';

interface CampaignDraftFormProps {
  campaign: SegmentCampaign;
  proceed: (
    initialValues: SegmentCampaignDraft,
    campaign: SegmentCampaign
  ) => void;
}

export const CampaignDraftForm: React.FC<CampaignDraftFormProps> = ({
  campaign,
  proceed
}) => {
  return (
    <div>
      <Form
        onSubmit={(details) => proceed(details, campaign)}
        initialValues={{
          name: campaign.name,
          partner: campaign.partner,
          incentives: campaign.incentives ?? [],
          team: campaign.team ?? '',
          managers: campaign.managers ?? [],
          goals: campaign.goals ?? [],
          segments: campaign.segments ?? [],
          pageConstraints: campaign.pageConstraints
        }}
        render={({ handleSubmit, submitting, values }) => {
          const showSecondPart = true;
          return (
            <form onSubmit={handleSubmit}>
              <CardContent
                className={css(() => ({
                  marginBottom: 'auto'
                }))}
              >
                <div
                  style={{
                    minWidth: '500px'
                  }}
                >
                  <Typography variant="body1" component="p" paragraph>
                    Fill in the initial details of your campaign to send to the
                    partner. You’ll be able to review the final links and
                    preview any rerouted links before scheduling the campaign.
                  </Typography>
                </div>
                <Divider />
                <br />
                <div
                  className={css((t) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: t.spacing(2),
                    rowGap: t.spacing(4)
                  }))}
                >
                  <FieldContainer>
                    <SectionHeading>Campaign details</SectionHeading>
                    <InputLabel>Campaign name</InputLabel>
                    <Typography variant="body2" component="p" paragraph>
                      Give your campaign a concise name that will help you and
                      the partner identify it.
                    </Typography>
                    <Field name="name" validate={required}>
                      {({ input, meta }) => {
                        const hasError = meta.error && meta.touched;
                        return (
                          <TextField
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            error={hasError}
                            helperText={
                              hasError &&
                              'Please provide a name for your campaign.'
                            }
                            onBlur={input.onBlur}
                            placeholder="Home and Garden Products Q4 2025"
                            onFocus={input.onFocus}
                            required
                            autoFocus
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    </Field>
                  </FieldContainer>
                  <FieldContainer>
                    <InputLabel>Campaign partner</InputLabel>
                    <Typography variant="body2" component="p" paragraph>
                      Who are you partnering with on this campaign?
                    </Typography>
                    <Field
                      name="partner"
                      validate={required}
                      render={({ input, meta }) => {
                        const hasError = meta.error && meta.touched;
                        return (
                          <CampaignPartnerSelector
                            {...input}
                            error={hasError}
                            helperText={
                              hasError &&
                              'Please select a partner for your campaign.'
                            }
                          />
                        );
                      }}
                    />
                  </FieldContainer>
                  {showSecondPart ? (
                    <>
                      <FieldContainer>
                        <Field
                          name="segments"
                          render={({ input }) => {
                            return (
                              <CampaignSegmentsPicker
                                partner={values.partner}
                                value={input.value}
                                onChange={input.onChange}
                                pageConstraints={values.pageConstraints}
                              />
                            );
                          }}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <Field name="incentives">
                          {({ input }) => {
                            return (
                              <>
                                <CampaignIncentivesSelector
                                  value={input.value}
                                  onChange={input.onChange}
                                  options={['cpc']}
                                />
                                <Uplift
                                  segments={values.segments}
                                  incentives={values.incentives}
                                  pageConstraints={values.pageConstraints}
                                />
                              </>
                            );
                          }}
                        </Field>
                      </FieldContainer>

                      <FieldContainer>
                        <Field name="goals">
                          {({ input }) => {
                            const incentives: CampaignIncentive[] =
                              values.incentives;
                            const cpc = incentives.find(
                              (i) => i.type === 'cpc'
                            ) as CampaignCPCIncentive | undefined;
                            const defaultClicksAmount = cpc
                              ? Math.floor(cpc.limit / cpc.amount)
                              : undefined;
                            return (
                              <CampaignGoalsSelector
                                value={input.value}
                                onChange={input.onChange}
                                options={['clicks']}
                                defaultAmounts={{ clicks: defaultClicksAmount }}
                              />
                            );
                          }}
                        </Field>
                      </FieldContainer>
                      <FieldContainer>
                        <Field name="pageConstraints">
                          {({ input }) => {
                            return (
                              <CampaignPageConstraintsPicker
                                segments={values.segments}
                                value={input.value}
                                onChange={input.onChange}
                              />
                            );
                          }}
                        </Field>
                      </FieldContainer>
                      <FieldContainer>
                        <SectionHeading>Campaign management</SectionHeading>
                        <InputLabel>Campaign team</InputLabel>
                        <Typography
                          variant="body2"
                          component="p"
                          paragraph
                          color="textPrimary"
                        >
                          Which team's forecasts should this campaign count
                          towards? Pick only one.
                        </Typography>
                        <Field name="team" validate={required}>
                          {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return (
                              <TeamSelector
                                value={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                error={hasError}
                                helperText={
                                  hasError &&
                                  'Please choose a team for your campaign.'
                                }
                              />
                            );
                          }}
                        </Field>
                      </FieldContainer>
                      <FieldContainer>
                        <InputLabel>Campaign managers</InputLabel>
                        <Typography
                          variant="body2"
                          component="p"
                          paragraph
                          color="textPrimary"
                        >
                          This person will be the point of contact for the
                          advertiser during the campaign.
                        </Typography>
                        <Field name="managers">
                          {({ input }) => (
                            <AutocompleteUserMultiById
                              spaceId={campaign.spaceId}
                              value={input.value}
                              onChange={input.onChange}
                              fullWidth
                            />
                          )}
                        </Field>
                      </FieldContainer>
                    </>
                  ) : null}
                </div>
              </CardContent>
              <CardActions>
                <div
                  className={css(() => ({
                    gridColumn: '2 / 3',
                    textAlign: 'right'
                  }))}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting || !values.partner}
                  >
                    Save and continue
                  </Button>
                </div>
              </CardActions>
            </form>
          );
        }}
      />
    </div>
  );
};
